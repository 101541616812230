<template>
  <div style="height: auto;background-color: #fff;border: 10px solid #edeff2;">
    <a-spin :spinning="spinning">
      <div class="search">
        <div style="margin-left:10px">
            服务状态：
            <a-radio-group v-model="type" @change="onTabChange">
              <a-radio-button value="">
                全部
              </a-radio-button>
              <a-radio-button value="consult">
                咨询中
              </a-radio-button>
              <a-radio-button value="pending">
                待开始
              </a-radio-button>
              <a-radio-button value="start">
                进行中
              </a-radio-button>
              <a-radio-button value="success">
                已完成
              </a-radio-button>
              <a-radio-button value="cancel">
                已取消
              </a-radio-button>
            </a-radio-group>
          </div>
      </div>
      <div>
        <div class="text">
          <h3>服务咨询列表</h3>
        </div>
        <div style="padding:15px">
          <a-table
            :columns="columns"
            style="min-height: 500px; background-color: #fff"
            :data-source="HeplTable"
            :rowKey="(record) => record.id"
            :pagination="false"
          >
            <span slot="orderStatus" slot-scope="text, record">
              <span v-if="text == 0 && record.discountPrice === 0">-</span>
              <span v-if="text == 0 && record.discountPrice !== 0">待付款</span>
              <span v-if="text == 1">已取消</span>
              <span v-if="text == 2">未付款</span>
              <span v-if="text == 3">待确认</span>
              <span v-if="text == 4">已支付</span>
            </span>
            <span slot="expireDate" slot-scope="text, record,index">
              {{record.expireDate?record.expireDate:'-'}}
            </span>
            <span slot="serviceStatus" slot-scope="text, record">
              <span v-if="text == 'consult'">咨询中</span>
              <span v-if="text == 'pending'" style="color:#02a7f0">待开始</span>
              <span v-if="text == 'start'" style="color:#f59a23">进行中</span>
              <span v-if="text == 'success'" style="color:#70b603">已完成</span>
              <span v-if="text == 'cancel'">已取消</span>
              <span v-if="text == 'expire'">已到期</span>
            </span>
            <span slot="discountPrice" slot-scope="text, record">
              <span v-if="text == 0 && record.serviceStatus == 'consult'">
                <a @click="SetPrice(record.id)">设置价格</a>
              </span>
              <span v-else-if=" !record.discount && record.serviceStatus == 'cancel'">
                <span>-</span>
              </span>
              <span v-else>
                ￥&nbsp;{{ text }}
              </span>
            </span>
            <span slot="option" slot-scope="text, record">
              <span v-if="record.serviceStatus === 'expire'">-</span>
              <a @click="ServiceRecords(record)" v-if="record.serviceStatus === 'pending' || record.serviceStatus === 'start' || record.serviceStatus === 'success'">服务记录</a>
              <a-popconfirm title="确认取消吗？" ok-text="是" cancel-text="否" @confirm="cancelOrder(record)">
                <a v-if="record.serviceStatus == 'consult'">取消订单</a>
              </a-popconfirm>
              <a @click="ServiceRecords(record)" v-if="record.serviceStatus == 'cancel' ">-</a>
              <a @click="comment(record)" style="margin-left:5px" v-if=" record.commentCount !== 0 && record.serviceStatus == 'success'">评价</a>
            </span>
          </a-table>
          <!-- table框的分页 -->
          <div
            style="
              margin-top: 28px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
            >
              共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
              {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
            </span>
            <a-pagination
              style="float: right"
              show-quick-jumper
              show-size-changer
              :page-size.sync="pagination.pageSize"
              :total="pagination.total"
              v-model="pagination.current"
              @change="pageChange"
              @showSizeChange="pageChange"
            />
          </div>
        </div>
      </div>
      <!-- 设置价格弹窗 -->
      <a-modal v-model="modalShow" title="设置价格" @cancel="handleCancel">
          <a-form-model :model="setPriceForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="reviseFormRules" ref="setPriceForm">
            <a-form-model-item label="定价 " prop="price">
              <a-input v-model="setPriceForm.price" placeholder="请输入" @blur="jisuan"/>
            </a-form-model-item>
            <a-form-model-item label="折扣率" prop="discount">
              <div style="display: flex;">
                <a-input style="border-right:0;border-radius: 4px 0 0 4px;" v-model="setPriceForm.discount" placeholder="请输入"  @blur="jisuan"  />
                <div style="display: flex;justify-content: center;align-items: center;border-right:1px solid #d9d9d9;border-bottom:1px solid #d9d9d9;border-top:1px solid #d9d9d9;border-radius: 0px 4px 4px 0px;height: 32px;width:44px;background-color: #ebebeb;">%</div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="折扣价 " prop="discountPrice">
              <a-input :disabled="true" v-model="setPriceForm.discountPrice" placeholder="折扣价=定价*折扣率" />
            </a-form-model-item>
          </a-form-model>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="handleCancel">
              取消
            </a-button>
            <a-button :style="{ marginRight: '8px' }" type="primary" @click="reviseSubmit">
              确认
            </a-button>
          </div>
      </a-modal>
      <!-- 服务记录 -->
       <a-modal v-model="modal2Show" title="服务记录" @cancel="handle2Cancel">
        <a-form-model :model="ServiceForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="ServiceFormRules" ref="ServiceForm">
          <a-form-model-item  label="服务时间" prop="time">
            <!-- <a-range-picker :format="'YYYY-MM-DD'" :value="[moment(this.setPriceForm.StartTime, 'YYYY-MM-DD'), moment(this.setPriceForm.EndTime, 'YYYY-MM-DD')]" style="margin-right: 15px;" @change="onChange" /> -->
            <a-range-picker :format="'YYYY-MM-DD'" v-model="ServiceForm.time"  @change="onChange" />
        </a-form-model-item>
          <a-form-model-item  label="内容记录" prop="serviceContent" >
            <a-textarea v-model="ServiceForm.serviceContent" auto-size placeholder="请输入" style="min-height: 120px;font-size: 16px"/>
          </a-form-model-item>
          <a-form-model-item label="状态"  prop="serviceStatus" >
              <a-select v-model="ServiceForm.serviceStatus" placeholder="请选择" @change="type5Change">
<!--                <a-select-option value="consult">-->
<!--                  咨询中-->
<!--                </a-select-option>-->
                <a-select-option value="pending">
                  待开始
                </a-select-option>
                <a-select-option value="start">
                  进行中
                </a-select-option>
                <a-select-option value="success">
                  已完成
                </a-select-option>
              </a-select>
            </a-form-model-item>
        </a-form-model>
        <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="handle2Cancel()">
              取消
            </a-button>
            <a-button :style="{ marginRight: '8px' }" v-if="this.buildType === 'new'" type="primary" @click="Submit">
              确认
            </a-button>
            <a-button :style="{ marginRight: '8px' }" v-if="this.buildType === 'edit'" type="primary" @click="editSubmit">
              确认
            </a-button>
          </div>
       </a-modal>
<!--       <a-modal class="commentDetail" v-model="modal3Show" title="评价" @cancel="handle3Cancel">-->
<!--         <div v-if="commentDetail.systemEvaluate">系统评价：{{ commentDetail.systemEvaluate }}</div>-->
<!--         <div v-if="commentDetail.feedbackAndSuggestions">反馈和意见：{{ commentDetail.feedbackAndSuggestions }}</div>-->
<!--         <div v-if="commentDetail.kfEvaluate">客服评价：{{ commentDetail.kfEvaluate }}</div>-->
<!--         <div v-if="commentDetail.serviceEvaluate">本次服务评价：{{ commentDetail.serviceEvaluate }}</div>-->
<!--       </a-modal>-->
       <!-- 服务记录抽屉 -->
       <a-drawer title="服务记录" placement="right" :closable="false" :visible="addVisible" @close="onClose" width="60%">
        <a-button type="primary" style="margin-bottom:15px" @click="newBuild">+新增</a-button>
        <a-table
          :columns="ServiceColumns"
          style="min-height: 500px; background-color: #fff"
          :data-source="ServiceTable"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
        <span slot="serviceTime" slot-scope="text, record">
          {{ record.serviceStartTime }} ~ {{ record.serviceEndTime }}
        </span>
          <span slot="serviceOption" slot-scope="text, record">
            <a @click="modify(record)" style="margin-right:5px">修改</a>
            <a-popconfirm title="确认删除吗？" ok-text="是" cancel-text="否" @confirm="deleteRecord(record)">
              <a>删除</a>
            </a-popconfirm>
          </span>
        <span slot="serviceStatus" slot-scope="text, record">
            <span v-if="text == 'consult'">咨询中</span>
            <span v-if="text == 'pending'">待开始</span>
            <span v-if="text == 'start'" >进行中</span>
            <span v-if="text == 'success'" >已完成</span>
            <span v-if="text == 'cancel'">已取消</span>
          </span>
        </a-table>
        <!-- table框的分页 -->
        <div
          style="
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
            <span
              style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
            >
              共{{ pagi2nation.total }}条记录 第 {{ pagi2nation.pageNum }} /
              {{ Math.ceil(pagi2nation.total / pagi2nation.pageSize) }} 页
            </span>
            <a-pagination
              style="float: right"
              show-quick-jumper
              show-size-changer
              :page-size.sync="pagi2nation.pageSize"
              :total="pagi2nation.total"
              v-model="pagi2nation.current"
              @change="page2Change"
              @showSizeChange="page2Change"
            />
          </div>
       </a-drawer>

      <!--      评价-->
      <Evaluate
          v-if="visibleEvaluate"
          :visible="visibleEvaluate"
          :serviceCode="serviceCode"
          :serConId="serConId"
          :isShowBtn="false"
          :orderNumber="orderNumber"
          v-on:closeMain="closeMain"
      />
    </a-spin>
  </div>
</template>

<script >
import {getServiceConcult,postServiceConcult,putOrder,getServiceRecord,postServiceRecord,putServiceRecord,delRecords} from "@/service/customerManagement_api";
import moment from "moment";
import Evaluate from "@/components/PaidView/Evaluate";
export default {
  components:{Evaluate},
  data(){
    return{
      visibleEvaluate: false,
      serviceCode: '',
      serConId: null,
      orderNumber:'',
      ServiceColumns:[
        {
          title: "服务时间",
        //   dataIndex: "serviceTime",
        //   key: "serviceTime",
          // align: "center",
          width: "35%",
          scopedSlots: { customRender: "serviceTime" },
        },
        {
          title: "服务内容",
          dataIndex: "serviceContent",
          key: "serviceContent",
          // align: "center",
          // width: "8%",
        },
        // {
        //   title: "状态",
        //   dataIndex: "serviceStatus",
        //   key: "serviceStatus",
        //   scopedSlots: { customRender: "serviceStatus" },
        //   // align: "center",
        //   // width: "8%",
        // },
        {
          title: "操作",
        //   dataIndex: "serviceName",
        //   key: "serviceName",
          // align: "center",
          // width: "8%",
          scopedSlots: { customRender: "serviceOption" },
        },
      ],
      ServiceTable:[],
      addVisible:false,
      modal3Show:false,
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      pagi2nation: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      ServiceForm:{
        time:[],
        serviceStatus:undefined,
        serviceContent:'',
      },
      modal2Show:false,
      setPriceForm:{discountPrice:''},
      labelCol: { span: 4},
      wrapperCol: { span: 18 },
      reviseFormRules: {
        price: [{ required: true, message: '请输入', trigger: 'blur' }],
        discount: [{ required: true, message: '请输入', trigger: 'blur' }],
        // discountPrice: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      ServiceFormRules:{
        time: [{ required: true, message: '请选择', trigger: 'change' }],
        serviceContent: [{ required: true, message: '请输入', trigger: 'blur' }],
        serviceStatus: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      modalShow:false,
      spinning:false,
      state:'',
      type:'',
      HeplTable:[],
      columns: [
          {
            title: "服务名称",
            dataIndex: "serviceName",
            key: "serviceName",
            // align: "center",
            // width: "8%",
          },
          {
            title: "提交时间",
            dataIndex: "createdTime",
            key: "createdTime",
            // align: "center",
            // width: "15%",
          },
          {
            title: "服务到期日期",
            dataIndex: "expireDate",
            key: "expireDate",
            scopedSlots: { customRender: "expireDate" },
          },
          {
            title: "账号名称",
            dataIndex: "accountName",
            key: "accountName",
            // align: "center",
            // width: "28%",
          },
          {
            title: "注册账号",
            dataIndex: "registerAcount",
            key: "registerAcount",
            // align: "center",
            // scopedSlots: { customRender: "cancelChannel" },
          },
          {
            title: "订单号",
            dataIndex: "orderNumber",
            key: "orderNumber",
            // align: "center",
            // scopedSlots: { customRender: "cancelChannel" },
          },
          {
            title: "订单金额",
            dataIndex: "discountPrice",
            key: "discountPrice",
            // align: "center",
            scopedSlots: { customRender: "discountPrice" },
          },
          {
            title: "订单状态",
            dataIndex: "orderStatus",
            key: "orderStatus",
            // align: "center",
            scopedSlots: { customRender: "orderStatus" },
          },
          {
            title: "服务状态",
            dataIndex: "serviceStatus",
            key: "serviceStatus",
            // align: "center",
            scopedSlots: { customRender: "serviceStatus" },
          },
          {
            title: "操作",
            align: "center",
            scopedSlots: { customRender: "option" },
          },
        ],
      StartTime:'',
      EndTime:'',
      id:'',
      commentDetail:'',
      buildType:'',
      recordId:'',
    }
  },
  created(){
    this.getTable()
  },
  methods:{
    closeMain(val){
      this.visibleEvaluate = val;
    },
    newBuild(){
      this.modal2Show = true
      this.buildType = "new"
    },
    async deleteRecord(record){
      const response = await delRecords(record.id);
      if (response.code === 0) {
        this.getDeatilTable()
        this.$message.success("操作成功");
      } else {
        this.$message.warning(response.message);
      }
    },
    modify(record){
      this.modal2Show = true
      this.buildType = "edit"
      this.recordId = record.id
    //   this.id = record.serConId
      this.ServiceForm.serviceStatus = record.serviceStatus
      this.ServiceForm.serviceContent = record.serviceContent
      this.setPriceForm.StartTime = record.serviceStartTime
      this.setPriceForm.EndTime = record.serviceEndTime
    //   this.$set(this.setPriceForm, 'time', [moment(record.serviceStartTime, 'YYYY-MM-DD'), moment(record.serviceEndTime, 'YYYY-MM-DD')])
      this.ServiceForm.time.push(moment(record.serviceStartTime),moment(record.serviceEndTime))
    },
    onClose(){
      this.addVisible = false
    },
    async comment(row){
      this.orderNumber = row.orderNumber;
      this.serviceCode = row.subscriptionServicesCode;
      this.serConId = row.id;
      this.visibleEvaluate = true;
    },
    // 获取单条服务记录
    async getDeatilTable(){
      let data = {
        pageNo: this.pagi2nation.pageNum,
        pageSize: this.pagi2nation.pageSize,
        serConId:this.id
      };
      const response = await getServiceRecord(data);
      if (response.code === 0) {
        // console.log("浏览请求",  response.data[0].id);
        this.ServiceTable = response.data;
        this.pagi2nation.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
    },
    async cancelOrder(record){
      this.id = record.id
      const data = {
        orderNumber:record.orderNumber,
        cancelType:"客服取消"
      }
      // 修改订单状态
      const response = await putOrder(data);
      if (response.code === 200) {
        await this.getTable()
        this.$message.success("操作成功");
      } else {
        this.$message.warning(response.message);
      }
      // // 服务状态也取消
      // let information = {
      //   pageNo: 1,
      //   pageSize: 10,
      //   serConId:this.id
      // };
      // const respond = await getServiceRecord(information);
      // if (respond.code === 0) {
      //   let info = {
      //     id:respond.data[0].id,
      //     serConId:this.id,
      //     serviceStatus:"cancel",
      //   }
      //   const res = await putServiceRecord(info);
      //   if (res.code === 0) {
      //     this.getTable()
      //   } else {
      //     this.$message.warning(res.message);
      //   }
      // } else {
      //   this.$message.warning(respond.message);
      // }
    },
    // 输入框计算事件
    jisuan(){
      if(this.setPriceForm.price && this.setPriceForm.discount){
        this.setPriceForm.discountPrice = this.setPriceForm.price * this.setPriceForm.discount * 0.01
      }
    },
    // 获取列表
    async getTable() {
      let data = {};
      data = {
        pageNo: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
        serviceStatus:this.type
      };
      const response = await getServiceConcult(data);
      if (response.code === 0) {
        // console.log("浏览请求", response.data);
        this.HeplTable = response.data;
        this.pagination.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
    },
    onTabChange(){
      this.getTable()
    },
    // 服务记录--状态
    type5Change(value) {
      this.ServiceForm.type = value
    },
    // 新增表单提交
    Submit(){
      this.$refs.ServiceForm.validate(async valid => { // 表单校验
        if(valid) {
          let data = {
            serConId:this.id,
            serviceStartTime:this.StartTime,
            serviceEndTime:this.EndTime,
            serviceStatus:this.ServiceForm.serviceStatus,
            serviceContent:this.ServiceForm.serviceContent,
          }
          const response = await postServiceRecord(data);
          if (response.code === 0) {
            this.$message.success("新增成功~");
            // 清空
            this.ServiceForm = {time:[],serviceStatus:undefined,serviceContent:''}
            this.StartTime = ''
            this.EndTime = ''
            this.modal2Show = false
            this.getDeatilTable()
            this.getTable()
          } else {
            this.$message.warning(response.message);
          }
        }
      })
    },
    //修改提交
    editSubmit(){
      this.$refs.ServiceForm.validate(async valid => { // 表单校验
        if(valid) {
          let data = {
            id:this.recordId,
            serConId:this.id,
            serviceStartTime:this.StartTime,
            serviceEndTime:this.EndTime,
            serviceStatus:this.ServiceForm.serviceStatus,
            serviceContent:this.ServiceForm.serviceContent,
          }
          const response = await putServiceRecord(data);
          if (response.code === 0) {
            this.$message.success("修改成功~");
            // 清空
            this.ServiceForm = {time:[],serviceStatus:undefined,serviceContent:''}
            this.StartTime = ''
            this.EndTime = ''
            this.modal2Show = false
            this.getDeatilTable()
            this.getTable()
          } else {
            this.$message.warning(response.message);
          }
        }
      })
    },
    // 弹窗取消
    handleCancel(){
      this.modalShow = false
      this.$refs.setPriceForm.resetFields()
    },
    handle2Cancel(){
      this.modal2Show = false
      this.$refs.ServiceForm.resetFields()
      this.ServiceForm = {time:[],serviceStatus:undefined,serviceContent:''}
    },
    handle3Cancel(){
      this.modal3Show = false
    },
    // 设置价格
    reviseSubmit(){
      this.$refs.setPriceForm.validate(async valid => { // 表单校验
        if(valid) {
          let data = {
            id:this.id,
            discount: this.setPriceForm.discount,
            discountPrice: this.setPriceForm.discountPrice,
            price: this.setPriceForm.price
          }
        //   console.log("表单",data)
          const response = await postServiceConcult(data);
          if (response.code === 0) {
            this.$message.success("设置成功~");
            // 清空
            this.modalShow = false
            this.setPriceForm = {
              discountPrice:''
            }
            this.getTable()
          } else {
            this.$message.warning(response.message);
          }
        //   this.modalShow = false
        //   this.setPriceForm = {
        //     discountPrice:''
        //   }
        }
      })
    },
    SetPrice(id){
      this.modalShow = true
      this.id = id
    },
    moment,
    async ServiceRecords(record){
      this.addVisible = true
      this.id = record.id  //大id
      this.getDeatilTable()
    },
    // 日期
    onChange(date, dateString){
      this.StartTime = dateString[0]
      this.EndTime = dateString[1]
    // this.setPriceForm.time = dateString
    //   this.getTable()
    },
    // 分页
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTable();
    },
    page2Change(page_no, page_size) {
      this.pagi2nation.pageNum = page_no;
      this.pagi2nation.pageSize = page_size;
      this.getDeatilTable();
    },
  }
}
</script>

<style scoped lang="scss">
  .search {
    background-color: #fff;
    width: 100%;
    height: 64px;
    /* padding: 20px; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* margin-bottom: 10px; */
    border-bottom:10px solid rgb(237, 239, 242)
  }
  .text{
    height: 45px;
    border-bottom:1px solid rgb(237, 239, 242);
    display: flex;
    align-items:center;
    padding-left:10px;
    h3{
      font-weight:bold
    }
  }
  ::v-deep .ant-table-thead > tr > th {
    font-weight: bold;
  }
  .commentDetail {
    div{
      display: block;
      margin-bottom: 10px
    }
  }
</style>
